export class Map {
	constructor(mapElements = []) {
		this.elements = mapElements;

		this.init();
	}

	init() {
		this.elements.forEach((element) => {
			element.addEventListener("click", this.setActiveRegion.bind(this));
		});
	}

	setActiveRegion(event) {
		const activeRegion = this.getActiveRegion(event.target);
		this.removeRegionClasses(".region", "active");
		activeRegion.classList.add("active");

		const activeRegionId = activeRegion.getAttribute("data-region");

		this.removeRegionClasses(".Map__region", "Map__region--active");
		const regionContainer = document.querySelector(
			`.Map__region[data-regionid='${activeRegionId}']`
		);
		regionContainer.classList.add("Map__region--active");
	}

	removeRegionClasses(elements, className) {
		document
			.querySelectorAll(elements)
			.forEach((element) => element.classList.remove(className));
	}

	getActiveRegion(element) {
		return element.closest(".region");
	}
}
