import axios from "axios";
import $ from "jquery";

const addToCart = () => {
	const btns = document.querySelectorAll(".ajax-add-to-cart");
	const popupContainer = document.querySelector(".popup-container");
	const popupContainerInnerContent = popupContainer.querySelector(
		".inner-content"
	);

	btns.forEach((btn) => {
		btn.addEventListener("click", (e) => {
			e.preventDefault();
			console.log("addtocartbtn clicked");

			if (btn.classList.contains("disabled")) return false;

			const product_id = btn.getAttribute("data-ajaxid");
			const qty = document.querySelector("input.qty");
			const variation_id = document.querySelector("input.variation_id");

			if (product_id) {
				const fd = new FormData();
				fd.append("product_id", product_id);
				fd.append("action", "ajaxAddToCart");

				if (variation_id) {
					fd.append("variation_id", variation_id.value);
				}

				if (
					qty &&
					qty.value > 1 &&
					document.querySelector("form.cart .inner").contains(btn)
				) {
					fd.append("quantity", qty.value);
				}

				axios.post(jsData.ajaxUrl, fd).then((res) => {
					if (res.data.success == true) {
						$(document.body).trigger("wc_fragment_refresh");
						console.log(res.data);

						if (document.querySelector(".Popup__main")) {
							popupContainer.classList.add("show");
							const { thumbnail, permalink, name, price } = res.data.data;
							document.querySelector(".Popup__main").innerHTML = `
            <a href="${permalink}" class="ProductListItem__link">
              ${thumbnail}
            </a>
            <div class="ProductListItem__footer">
              <a class="ProductListItem__link" href="${permalink}">
                <h3 class="ProductListItem__title">${name}</h3>
              </a>
              <div class="ProductListItem__price">${price}</div>
            </div>
            `;
						}

						// setTimeout(() => {
						// const event = new Event("show-cart-preview");
						// document.body.dispatchEvent(event);
						// }, 500);

						// ("Produkt został dodany do koszyka");
						// setTimeout(() => {
						// if (popupContainer.classList.contains("show")) {
						// popupContainer.classList.remove("show");
						// popupContainerInnerContent.textContent = "Proszę czekać...";
						// }
						// }, 500);
					}
				});
			}
		});
	});
};

export default addToCart;
