import { isMobile, handleMobileCategoryList } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import SearchForm from "./searchform";
import gridListSwitch from "./woocommerce/grid-list-switch";
import quantityButtonsHandler from "./woocommerce/quantity-buttons";
import addToCart from "./woocommerce/add-to-cart";
import faqContact from "./faq";
import "./FrontPageHeroSlider";
import "./NewsSlider";
import { Map } from "./Map";

window.addEventListener("load", () => {
	const map = new Map(document.querySelectorAll(".st0"));
	new SiteHeader();
	new FloatLabels();

	const shopMenuContainer = document.querySelector(".shop-menu-container");
	if (shopMenuContainer) {
		document.body.addEventListener("show-cart-preview", () => {
			shopMenuContainer.classList.add("temp-open");
			setTimeout(() => {
				shopMenuContainer.classList.remove("temp-open");
			}, 3500);
		});
	}

	const searchFormContainer = document.querySelector(".search-form-container");
	if (searchFormContainer) {
		new SearchForm(searchFormContainer);
	}

	if (document.querySelector(".ajax-add-to-cart")) {
		addToCart();
	}

	if (document.querySelector(".qty-wrapper")) {
		quantityButtonsHandler();
	}

	if (document.querySelector(".grid-list-selection")) {
		gridListSwitch();
	}

	if (document.querySelector(".faqs-container")) {
		new faqContact();
	}

	const mobileCategoriesListBtn = document.querySelector(
		".show-mobile-category-list"
	);
	if (mobileCategoriesListBtn) {
		handleMobileCategoryList(mobileCategoriesListBtn);
	}

	toggleMenu();

	if (isMobile()) {
		closeMenu();
		addSticky();
	}
});

window.onresize = () => {
	if (isMobile()) {
		closeMenu();
		addSticky();
	}
};

const hidePopup = () => {
	document.querySelector(".Popup").classList.remove("show");
};

document.querySelector(".Popup__close").addEventListener("click", hidePopup);

/**
 *  Add label and require field to woocommerce product vat number on checkout page
 */

window.addEventListener("load", () => {
	const billingVatNumber = document.querySelector("#billing_vat_number_field");

	if (billingVatNumber) {
		const labelElement = document.createElement("label");
		labelElement.innerText = "NIP lub PESEL";
		billingVatNumber.appendChild(labelElement);
		const checkbox = document.getElementById("billing_invoice_ask");

		const checkoutForm = document.querySelector("form.checkout");

		const setHTMLValidate = () => {
			document
				.getElementById("billing_vat_number")
				.setAttribute("required", true);
			checkoutForm.removeAttribute("novalidate");
		};

		const removeHTMLValidate = () => {
			document
				.getElementById("billing_vat_number")
				.setAttribute("required", false);
			checkoutForm.setAttribute("novalidate", true);
		};

		checkbox.addEventListener("change", () => {
			if (checkbox.checked) {
				setHTMLValidate();
			} else {
				removeHTMLValidate();
			}
		});
	}
});
